import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, TESTNET } from "./chains";

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [MAINNET]: {
    // bsc mainnet
    Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    GMT: "0x99e92123eB77Bc8f999316f622e5222498438784",
    Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
    Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
    Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
    AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
    AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    OrderBook: "0x1111111111111111111111111111111111111111",
    OrderBookReader: "0x1111111111111111111111111111111111111111",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    XGMT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
    GMT_USDG_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
    XGMT_USDG_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
    GMT_USDG_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
    XGMT_USDG_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
    USDG_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
    XGMT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
    GMT_USDG_FARM_TRACKER_XGMT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
    XGMT_USDG_FARM_TRACKER_XGMT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
    AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
    AUTO_USDG_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
    AUTO_USDG_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
    AUTO_USDG_FARM_TRACKER_XGMT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
    AUTO_USDG_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [TESTNET]: {
    // bsc testnet
    Vault: "0x1B183979a5cd95FAF392c8002dbF0D5A1C687D9a",
    Router: "0x10800f683aa564534497a5b67F45bE3556a955AB",
    Reader: "0x98D4742F1B6a821bae672Cd8721283b91996E454",
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    OrderBook: "0x9afD7B4f0b58d65F6b2978D3581383a06b2ac4e9",
    OrderBookReader: "0x0713562970D1A802Fa3FeB1D15F9809943982Ea9",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    NATIVE_TOKEN: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [ARBITRUM_TESTNET]: {
    // arbitrum testnet
    Vault: "0xBc9BC47A7aB63db1E0030dC7B60DDcDe29CF4Ffb",
    Router: "0xe0d4662cdfa2d71477A7DF367d5541421FAC2547",
    VaultReader: "0xFc371E380262536c819D12B9569106bf032cC41c",
    Reader: "0x2E093c70E3A7E4919611d2555dFd8D697d2fC0a1",
    GlpManager: "0xD875d99E09118d2Be80579b9d23E83469077b498",
    RewardRouter: "0x0000000000000000000000000000000000000000",
    RewardReader: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
    GLP: "0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    GMX: "0x0000000000000000000000000000000000000000",
    ES_GMX: "0x0000000000000000000000000000000000000000",
    BN_GMX: "0x0000000000000000000000000000000000000000",
    USDG: "0xBCDCaF67193Bf5C57be08623278fCB69f4cA9e68",
    ES_GMX_IOU: "0x0000000000000000000000000000000000000000",
    StakedGmxTracker: "0x0000000000000000000000000000000000000000",
    BonusGmxTracker: "0x0000000000000000000000000000000000000000",
    FeeGmxTracker: "0x0000000000000000000000000000000000000000",
    StakedGlpTracker: "0x0000000000000000000000000000000000000000",
    FeeGlpTracker: "0x0000000000000000000000000000000000000000",

    StakedGmxDistributor: "0x0000000000000000000000000000000000000000",
    StakedGlpDistributor: "0x0000000000000000000000000000000000000000",

    GmxVester: "0x0000000000000000000000000000000000000000",
    GlpVester: "0x0000000000000000000000000000000000000000",

    OrderBook: "0xebD147E5136879520dDaDf1cA8FBa48050EFf016",
    OrderBookReader: "0xC492c8d82DC576Ad870707bb40EDb63E2026111E",

    PositionRouter: "0xB4bB78cd12B097603e2b55D2556c09C17a5815F8",
    PositionManager: "0x168aDa266c2f10C1F37973B213d6178551030e44",

    // UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
  },
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    GlpManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
  },
  // [AVALANCHE]: {
  //   // avalanche
  //   Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
  //   Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
  //   VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
  //   Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
  //   GlpManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
  //   RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
  //   RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
  //   NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  //   GLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
  //   GMX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
  //   ES_GMX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
  //   BN_GMX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
  //   USDG: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
  //   ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

  //   StakedGmxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
  //   BonusGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
  //   FeeGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
  //   StakedGlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
  //   FeeGlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

  //   StakedGmxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
  //   StakedGlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

  //   GmxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
  //   GlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

  //   OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
  //   OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
  //   OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

  //   PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
  //   PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

  //   TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
  //   ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
  //   ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
  // },
  [AVALANCHE]: {
    // avalanche
    Vault: "0xe2165dE9D5517C8e3C64030F82FCd82cBe23BF42",//replaced 0xBB73e4aDF6A37b6003FdC3AB197dB9e0C5e97BcB 
    Router: "0x271FA7714E3F2c95E20D655A905D0694ea7f0Bce",//replaced 0x03dCF3F09c0150a4eF6bd534cFfBCcCD168A1774 
    VaultReader: "0xf8a7E9562354D3684BBaC9e83e51ceF43406199e",//replaced 0x26CC909199421d27f8511beD685314f934d0d7B2 
    Reader: "0xE0b3e5f9B5F261437C481b76C28C6dAEcf817De7",//replaced 0x9aa76B7a271AeD55F8070D9E9863e224D0eC15C1 
    GlpManager: "0x59D76BB96AeB6873a1bdB80f13123D680fD978e9",//replaced 0xE032c9c3343cDFFdF02f518560efF429e82b5b1d 
    RewardRouter: "0x0da6014A67BA558BD7B496B1ae77E15B92351ED4",//replaced 0xEa9590D5b874bC267c11DAf68fbe7EEBD2817083 
    RewardReader: "0x126F7365a05C9568a9394b7C6Bc9C9CcAa8da236",//replaced 0xad7f225060Fc97b9A11010c0e28F1C7aD912ca59
    NATIVE_TOKEN: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", //0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7
    GLP: "0x5C2DC7dc86075F778f00E8B9ae3444485D7Dc60d",//replaced 0x934D5a622B7C50Eb32CF33C92141d529852A2DA7 
    GMX: "0xf675Dc24f578f238E1c180E6bBbA907d1f40485b", //replaced 0x0798E09E85Ac8Ea0c5a346f15eAb8A7270Fa7036 
    ES_GMX: "0x91b3108b0BE949B56D7988Ba823b51173fBB0F75",//replaced 0xcAe38De2A815b057353214b8Db77fFFB4669553C 
    BN_GMX: "0x70cF9AD97AfADabE0Ec8548DE4D20c6D5C025945",//replaced 0xB6857E833965A852015bb6e8b9268437d1F15771 
    USDG: "0x891c54856914c18332C542704d80b63795C744D0", //replaced 0xB57e22de119F8e8488e184ee860a10584180fF5c 
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedGmxTracker: "0xC086F36A45686840aC37ca0f52c211e9F414508d",//replaced 0xFC7F9F0E331A277ddc3bF96dA8f180b0967bED8f 
    BonusGmxTracker: "0xf5a0Bb98007f40aEb518889464e681Fa6Edb64CF",//replaced 0xd29669137182b0555a72d7F665fb6244aA49Cf97 
    FeeGmxTracker: "0x71C50FA91B68f273965d2611F7776E8D84FD50Ec",//replaced 0x4E051911Ae43F5BEdd45161481F829db8168B883 
    StakedGlpTracker: "0xA25eD0336f86a16583d51AceB11F3623FB2E0934",//replaced 0x2232C632F84a71fe35BF5cf13EF137850bf161EF 
    FeeGlpTracker: "0xDc17eb86F31820caF7509168705408748ccc7e3D",//replaced 0xdA387f14bA75201C19408ecE677D16f809ec0C39 

    StakedGmxDistributor: "0x0EC0AD9F357e44739000c841777184901d1B18BD",//replaced 0xDb789dD9e43c723efA9670c9aa9e62443f78F702 
    StakedGlpDistributor: "0xEb04743a840Ccca92554FF036E29D2567134d448",//replaced 0x596cFDe27734275763abc8aCA0270d9d38D88706 

    GmxVester: "0xbd996B2F41D2B8A7daa6c6d32F779C0f5DD770E8",//replaced 0x38138550751e32A7fD085A5B2183Eb573A7ac937 
    GlpVester: "0x12BD3c351c1e457dF809943C908732bFB1688c67",//replaced 0x769E5097bFb0E5244D7404dbA60e09a0B8922614 

    OrderBook: "0x723f2aB9803527a1C76a7AB35a11E88450C46c3F",//replaced 0xcdFfB35Ec51475a5429f81b1526338d4ae847C60 
    OrderExecutor: "0x723f2aB9803527a1C76a7AB35a11E88450C46c3F",//replaced 0xcdFfB35Ec51475a5429f81b1526338d4ae847C60
    OrderBookReader: "0x79e094651a40d202656DA850dD0924b993Ca9E26",//replaced 0x312323ab3518A7EFA7b238319255370bc22328c4 

    PositionRouter: "0xE74E0453B6Cbbc45B1B936907b511e78Cf8F511e",//replaced 0x43de6E4cF7b3717AC821433b12Ab1e1Bb2737E95 
    PositionManager: "0xc763f97A95E504c6c69AB73aE049c1286baCC89E",//replaced 0xf2884F30469b87b59cD972a77d26699c7ba82A9F

    TraderJoeGmxAvaxPool: "0x4BD23FB515E5bA854fef303E992Db98c2719Ee0a",//replaced 
    ReferralStorage: "0x56D4cd1Af9060BdAd1e90ABDd57f2468d853BAe5",//replaced 0x1cBF39543d43eC32E29c0DfE68b0Bd57967b7481 
    ReferralReader: "0xaD20DaD99CCd259c14F7075b54208f957CBB4691",//replaced 0xf1477551d4396DB344285E33d9ffF94CC29D399A 
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
